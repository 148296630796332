//  breakpoints
//----------------------------------------------------------------------
$breakpoint-sp: 767px;
$breakpoint-pc: 1200px;


//  colors
//----------------------------------------------------------------------
$green: #1fab66;

//  font
//----------------------------------------------------------------------
@mixin fa {
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
