@import '_config';
@import '_sprite';


/*
パーツ

Markup:
============================================================*/
.topSection {
	padding: 90px 0;
	&_inner {
		width: 1200px;
		margin: 0 auto;
	}
	&-img {
		padding: 140px 0 0;
		background: #fff url(../images/top/topRecruit_bg.png) center top no-repeat;
	}
}
.contents {
	width: 1200px;
	margin: 0 auto 80px;
	& > :first-child {
		margin-top: 0;
	}
	&-hr {
		display: flex;
		display: -webkit-flex;
		justify-content: space-between;
		-webkit-justify-content: space-between;
		&-R {
			flex-direction: row-reverse;
			-webkit-flex-direction: row-reverse;
		}
	}
}
.main {
	width: 880px;
	& > :first-child {
		margin-top: 0;
	}
}
.side {
	width: 260px;
	& > :first-child {
		margin-top: 0;
	}
}
.editor {
	& > :first-child {
		margin-top: 0;
	}
}
.section {
	&_narrow {
		width: 960px;
		margin: 0 auto;
		& > :first-child {
			margin-top: 0;
		}
	}
	&_common {
		margin-top: 60px;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.topSection {
		padding: 30px 10px;
		&_inner {
			width: 100%;
		}
	}
	.contents {
		width: 100%;
		padding: 20px 10px;
		margin: 0 0 40px;
		&-hr {
			display: block;
		}
	}
	.main {
		width: 100%;
	}
	.side {
		margin-top: 30px;
		width: 100%;
	}
}
/*
スライダー再定義 mainSlider

Markup:
{$modifiers}

.btn-red - red

Styleguide 0.0.0
============================================================*/
.mainSlider li {
	// height: 500px;
	// width: 100%;
	a {
		display: block;
	}
    img {
		margin: 0 auto;
		// width: 100%;
	 //    height: 500px;
	 //    object-fit: cover;
	 //    font-family: 'object-fit: cover;';
	}
}
.slick-dots {
	margin-top: 20px !important;
	position: static !important;
}
.slick-dots li button {
	border-radius: 50%;
	width: 10px !important;
    height: 10px !important;
    border: solid 1px #cecece !important;
	border-radius: 50%;
}
.slick-dots li button:before {
	font-size: 20px !important;
	color: rgba(255,255,255,1) !important;
	opacity: 1 !important;
	top: -3px !important;
    left: -3px !important;
}
.slick-dots li.slick-active button:before {
    opacity: 1 !important;
    font-size: 4rem !important;
    color: #27d480 !important;
}
@media screen and (max-width: $breakpoint-sp){
	.slick-dots {
		margin-top: 0px !important;
	}
}
/*
topAbout

Markup:
<section class="topSection">
	<p class="topAbout">
		<p class="topAbout_img"><img src="http://satyr.io/620x570/dddddd" alt=""></p>
		<div class="topAbout_body">
			<p class="topAbout_title topCommon_title"><span>テｋスト</span>タイトル</p>
			<p class="topAbout_text">テキストテキスト</p>
			<p class="topAbout_btn"><a href="#">事業内容について</a></p>
		</div>
	</p>
</section>
============================================================*/
.topAbout {
	display: flex;
	display: -webkit-flex;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	align-items: center;
	-webkit-align-items: center;
	&_img {
		margin-top: 0;
	}
	&_body {
	}
	&_title {
	}
	&_text {
		line-height: 2;
		font-size: 1.6rem;
		text-align: center;
	}
	&_btn {
		width: 360px;
		margin: 30px auto 0;
		a {
			text-align: center;
			color: #fff;
			background: linear-gradient(to right, #3eac64, #50da7f);
			box-shadow: 0 2px #aff0d0;
			display: block;
			border-radius: 20px;
			padding: 8px 10px;
			&:before {
			    @include fa();
			    content: '\f105';
			    margin-right: 5px;
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp){
	.topAbout {
		display: block;
		&_img {
			text-align: center;
		}
		&_body {
			margin-top: 20px;
		}
		&_text {
			margin-top: 10px;
			text-align: left;
			br {
				display: none;
			}
		}
		&_btn {
			width: 250px;
			margin: 20px auto 0;
			padding: 0 20px;
		}
	}
}
.topRecruit {
	padding: 60px 0 40px;
	background-color: #fff;
	&_img {
		text-align: center;
		margin-top: 40px;
	}
	&_text {
		font-size: 1.6rem;
		line-height: 2;
		text-align: center;
	}
}
@media screen and (max-width: $breakpoint-sp){
	.topRecruit {
		padding: 30px 15px;
		&_text {
			text-align: left;
		}
	}
}

/*
topNews

Markup:
<div class="topNews">
	<p class="topNews_title">新着情報</p>
	<ul class="topNews_list">
		<li class="topNews_item">
			<p class="topNews_date">2019.12.30</p>
			<p class="topNews_lavel">テキスト</p>
			<p class="topNews_text"><a href="#">テキストテキストテキストテキスト</a></p>
		</li>
	</ul>
	<p class="topNews_viewMore"><a href="#">一覧を見る</a></p>
</div>
============================================================*/
.topNews {
	&_title {
		text-align: center;
		font-weight: bold;
		margin-top: 0;
		font-size: 3.2rem;
	}
	&_list {
	}
	&_item {
		padding: 15px;
		border-top: dotted 1px #cbcbcb;
		display: flex;
		display: -webkit-flex;
		align-items: flex-start;
		-webkit-align-items: flex-start;
		&:last-child {
			border-bottom: dotted 1px #cbcbcb;
		}
	}
	&_date {
		margin-top: 0;
		font-size: 1.4rem;
	}
	&_lavel {
		font-size: 1.2rem;
		margin-top: 0;
		margin-left: 70px;
		width: 100px;
		color: #fff;
		text-align: center;
		padding: 2px 5px;
		border-radius: 5px;
		&-red {
			background-color: #f53d43;
		}
	}
	&_text {
		font-size: 1.4rem;
		width: 890px;
		margin: 0 0 0 15px;
	}
	&_viewMore {
		font-size: 1.4rem;
		margin-top: 15px;
		text-align: right;
		a {
			font-size: 1.4rem;
			font-weight: bold;
			&:before {
			    @include fa();
			    content: '\f138';
			    color: #3ea863;
			    margin-right: 5px;
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp){
	.topNews {
		&_title {
			font-size: 2.5rem;
		}
		&_item {
			display: block;
			padding: 10px 0;
		}
		&_lavel {
			margin: 5px 0 0;
		}
		&_text {
			margin: 10px 0 0;
			width: 100%;
		}
	}
}


.topBnr {
	background: #f6fdf0 url(../images/global/tanaoroshi_bg.png) center center no-repeat;
	background-size: cover;
	&_img {
		margin-top: 0;
	}
}
.commonTable {
	th {
		width: 220px;
		background-color: #f3f3f3;
	}
	th,td {
		& > :first-child {
			margin-top: 0;
		}
	}
	&_position {
		position: relative;
	}
	&_mapBtn {
		width: 120px;
		height: 36px;
		position: absolute;
		right: 20px;
		top: 0;
		bottom: 0;
		margin: auto 0;
		a {
			text-align: center;
			border-radius: 20px;
    		padding: 5px 10px;
			display: block;
			background: #fff;
		    border: solid 1px #1fab66;
		    color: #1fab66;
		    box-shadow: 0 1px #1fab66;
		    &:before {
			    @include fa();
			    content: '\f041';
			    font-size: 2rem;
			    color: $green;
			    margin-right: 5px;
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp){
	.commonTable {
		th,td {
			display: block;
			width: 100%;
		}
	}
}
/*
ニュース

Markup:
<ul class="news_list">
	<li class="news_item">
		<p class="news_date">2020.12.30</p>
		<p class="news_lavel news_lavel-red">お知らせ</p>
		<p class="news_text"><a href="#">テキストテキストテキストテキスト</a></p>
	</li>
============================================================*/
.news {
	&_list {
	}
	&_item {
		padding: 15px;
		border-bottom: dotted 1px #cbcbcb;
		display: flex;
		display: -webkit-flex;
		align-items: flex-start;
		-webkit-align-items: flex-start;
	}
	&_date {
		margin-top: 0;
		font-size: 1.4rem;
	}
	&_lavel {
		font-size: 1.2rem;
		margin-top: 0;
		margin-left: 40px;
		width: 100px;
		color: #fff;
		text-align: center;
		padding: 2px 5px;
		border-radius: 5px;
		&-red {
			background-color: #f53d43;
		}
	}
	&_text {
		width: calc(100% - 230px);
		font-size: 1.4rem;
		margin: 0 0 0 15px;
	}
}
@media screen and (max-width: $breakpoint-sp){
	.news {
		&_item {
			display: block;
			padding: 10px 0;
		}
		&_lavel {
			margin: 5px 0 0;
		}
		&_text {
			width: 100%;
			margin: 10px 0 0;
		}
	}
}

/*
formTable

Markup:

============================================================*/
.formTable {
	th {
		width: 300px;
		vertical-align: top;
	}
	td {
		word-break: break-all;
	}
	&-must {
		&:before {
			content: '必須';
			font-size: 1.2rem;
			text-align: center;
			background-color: #f43838;
			color: #fff;
			border-radius: 5px;
			margin-right: 5px;
			padding: 2px 5px;
		}
	}
	&-any {
		&:before {
			content: '任意';
			font-size: 1.2rem;
			text-align: center;
			background-color: #888888;
			color: #fff;
			border-radius: 5px;
			margin-right: 5px;
			padding: 2px 5px;
		}
	}

}
@media screen and (max-width: $breakpoint-sp){
	.formTable {
		th,td {
			display: block;
			width: 100%;
		}
	}
}
.form {
	&_input {
		width: 100%;
		border: solid 1px #dcdbdc;
		&-w110 {
	    	width: 110px;
	    }
		&-w330 {
			width: 330px;
		}
		&-w400 {
			width: 400px;
		}
		&-w600 {
			width: 600px;
		}
		&-post {
			width: 250px;
		}
	}
	&_noteText {
		margin-top: 10px;
		text-align: center;
	    font-size: 1.3rem;
	    color: #888888;
	}
	&_select {
	    border: solid 1px #dcdcdc;
	    border-top: solid 1px #a5a5a5;
	    border-radius: 5px;
	    padding: 5px;
	    height: 40px;
	    width: 200px;
	}
	&_text {
		margin-top: 5px;
		color: #888888;
		font-size: 1.5rem;
	}
	&_subText {
		margin-left: 10px;
	}
	&_postBtn {
	    background-color: #959595;
	    border-radius: 5px !important;
	    width: 90px;
	    padding: 0;
	    cursor: pointer;
	    color: #fff;
	    line-height: 38px;
	    margin-left: 10px;
	}
	&_messageBox {
		height: 100px;
		padding: 5px;
    	border: solid 1px #e5e5e5 !important;
	}
	&_agreeText {
		border: dotted 1px #b2b2b2;
	    padding: 20px;
	    height: 230px;
	    word-break: break-all;
	    overflow-y: scroll;
	    margin-top: 50px;
	    & > :first-child {
			margin-top: 0;
		}
	}
	&_agree {
		padding: 20px 0;
		text-align: center;
		width: 400px;
		margin: 30px auto 0;
		border: dotted 1px #b2b2b2;
	}
	&_list {
		font-size: 0;
		margin-top: 0;
		li {
			display: block;
		}
		&-li {
			li + li {
				margin-top: 5px;
			}
		}
		&-hr {
			display: flex;
			display: -webkit-flex;
			flex-wrap: wrap;
			-webkit-flex-wrap: wrap;
			li {
				padding: 3px 0;
				margin-right: 20px;
				display: block;
				display: flex;
				display: -webkit-flex;
				align-items: center;
				-webkit-align-items: center;
			}
		}
	}
	&_formItem {
		display: inline-block;
		vertical-align: middle;
	}
	&_radioItem {
		display: inline-block;
		margin-right: 20px;
	}
}
@media screen and (max-width: $breakpoint-sp){
	.form {
		&_input {
			&-w110 {
				width: 70px;
			}
			&-date {
				width: 115px;
			}
			&-w400 {
				width: 100%;
			}
			&-w330 {
				width: 100%;
			}
			&-w600 {
				width: 100%;
			}
			&-post {
				width: 150px;
			}
		}
		&_text {
			display: block;
		}
		&_subText {
			display: block;
			margin: 5px 0 0;
		}
		&_agree {
			width: 100%;
		}
		&_agreeText {
			margin-top: 30px;
		}
		&_select {
			&-w350 {
		    	width: 100%;
		    }
		    &-w450 {
		    	width: 100%;
		    	font-size: 1.2rem;
		    }
		    &-w660 {
		    	width: 100%;
		    	font-size: 1.2rem;
		    }
		}
	}
}
/*
sdgs

Markup:
<div class="sdgs_set">
	<p class="sdgs_setTitle">アセットグループ 経営理念</p>
	<div class="sdgs_setBody">
		<p class="sdgs_setBigText01">Asset as No.1</p>
		<p class="sdgs_setText">～ 存在感のある会社 ～</p>
	</div>
</div>
<div class="sdgs_imgText">
	<p class="sdgs_imgTextImg"><img src="../assets/images/content/sdgs_img02.jpg" alt=""></p>
	<div class="sdgs_imgTextBody">
		<p class="f18 mt0">1. 省エネルギー、低炭素経営</p>
		<p class="sdgs_imgTextText">私たちは、グローバルな社会課題として顕在化している気候変動等を考慮し、省エネルギー及び低炭素経営を推進します。<br>具体的には、事業で使用する棚卸業務用機器等のさらなる省エネ化と、オフィスで使用する電力使用量の低減のためのLED照明導入、シェアオフィスの積極採用、車両で使用する燃料の使用量低減などにより、事業で排出するCO2を大幅に低減します。</p>
	</div>
</div>
============================================================*/
.sdgs {
	&_set {
		width: 800px;
		margin: 30px auto 0;
		border: solid 2px $green;
		border-radius: 10px;
	}
	&_setTitle {
		margin-top: 0;
		font-size: 1.8rem;
		text-align: center;
		font-weight: bold;
		color: #fff;
		padding: 5px;
		border-radius: 7px 7px 0 0;
		background-color: $green;
	}
	&_setBody {
		padding: 20px;
		font-weight: bold;

	}
	&_setBigText01 {
		margin-top: 0;
		font-size: 3.5rem;
		font-weight: bold;
		text-align: center;
		color: red;
		font-family: arial;
	}
	&_setText {
		margin-top: 0;
		font-size: 1.8rem;
		text-align: center;
		span {
			font-size: 2.6rem;
			color: red;
			font-weight: bold;
		}
	}
	&_set02 {
		width: 800px;
		margin: 30px auto 0;
		margin-top: 20px;
	}
	&_set02Title {
		background-color: #ffa619;
		font-size: 1.8rem;
		text-align: center;
		font-weight: bold;
		color: #fff;
		padding: 5px;
		border-radius: 7px 7px 0 0;
	}
	&_set02Body {
		background-color: #fff6e7;
		padding: 20px 30px;
		li + li {
			margin-top: 10px;
		}
	}
	&_set02Lead {
		margin-top: 0;
		color: #ffa619;
		font-size: 2.2rem;
		font-weight: bold;
		text-align: center;
	}
	&_section {
		margin-top: 70px;
	}
	&_imgText {
		margin-top: 40px;
		display: flex;
		display: -webkit-flex;
		justify-content: space-between;
		-webkit-justify-content: space-between;
		&-R {
			flex-direction: row-reverse;
			-webkit-flex-direction: row-reverse;
		}
	}
	&_imgTextImg {
		text-align: center;
		margin-top: 0;
	}
	&_imgTextBody {
		width: 590px;
	}
	&_imgTextText {
		margin-top: 10px;
		padding-left: 20px;
	}
}
.sdgsTable {
	th,td {
		vertical-align: top;
		padding: 10px;
		border: solid 1px #e5e5e5;
		& > :first-child {
			margin-top: 0;
		}
	}
	thead {
		td {
			text-align: center;
			color: #fff;
			background-color: #1fab66;
		}
	}
	th {
		background-color: #e8fcef;
	}
	td {
		width: 240px;
	}
}
@media screen and (max-width: $breakpoint-sp){
	.sdgs {
		&_section {
			margin-top: 50px;
		}
		&_set {
			width: 100%;
		}
		&_setBody {
			padding: 15px;
		}
		&_set02 {
			width: 100%;
		}
		&_set02Body {
			padding: 15px;
		}
		&_set02Lead {
			font-size: 2rem;
		}
		&_imgText {
			display: block;
		}
		&_imgTextBody {
			margin-top: 20px;
			width: 100%;
		}
		&_imgTextText {
			padding-left: 0;
			margin-top: 10px;
		}
	}
}
.pageTable {
	th {
		text-align: center;
		background-color: #f3f3f3;
	}
	th,td {
		border: 1px solid #e5e5e5;
	}
}
.tNote {
	font-size: 1.4rem;
	color: #888888;
}
@media screen and (max-width: $breakpoint-sp){
	.clientTable {
		td {
			display: block;
			padding: 15px 5px;
			&:nth-child(2) {
				border-top: 1px solid #e5e5e5;
			}
		}

	}
}
