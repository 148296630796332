@import '_config';
@import '_sprite';

@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css";
@import "https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap";
@import "_default";
@import "_shared";
@import "_global";
@import "_parts";
@import "_module";

//js plugin
@import "_plugins/_slick";
@import "_plugins/_slick-theme";
