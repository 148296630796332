@import '_config';
@import '_sprite';

/*
パーツ

Markup:
============================================================*/
.pageTitle {
	padding: 30px 0;
	background-color: #eafcf0;
    &_inner {
        width: 1200px;
        margin: 0 auto;
    }
    &_text {
        font-size: 3.2rem;
        font-weight: bold;
        margin-top: 0;
        display: inline-block;
    }
    &_label {
        background-color: #f53d43;
        display: inline-block;
        font-size: 1.2rem;
        margin: 10px;
        width: 100px;
        color: #fff;
        text-align: center;
        padding: 2px 5px;
        border-radius: 5px;
        position: relative;
        top: -5px;
    }
	span {
		font-size: 1.7rem;
        display: block;
	}
}
.topCommon {
    &_title {
        margin-top: 0;
        text-align: center;
        font-size: 6rem;
        line-height: 74px;
        span {
            // font-size: 10rem;
            border-top: solid 25px #333;
            border-image-source: url(../images/top/uLine_bg.png);
            border-image-slice: 30;
            border-image-repeat: repeat;
            border-image-width: 0 0 25px;
        }
        &-big {
            display: inline-block;
            font-size: 10rem;
        }
    }
}
@media screen and (max-width: $breakpoint-sp){
    .pageTitle {
        padding: 20px 10px;
        &_inner {
            width: 100%;
        }
        &_label {
            margin: 10px 0 0;
        }
    }
    .topCommon {
        &_title {
            font-size: 3rem;
            line-height: 1.4;
            &-big {
                font-size: 5rem;
            }
        }
    }
}
.errorMsg {
    margin-top: 5px;
    font-size: 1.3rem;
    color: red;
}
.linkText {
    color: $green;
    text-decoration: underline;
}
.headLine1 {
    font-size: 3rem;
    font-weight: bold;
    padding: 10px;
    border-top: solid 3px $green;
    border-bottom: solid 3px $green;
}
.headLine2 {
    margin: 50px 0 40px;
    font-size: 2.4rem;
    font-weight: bold;
    padding-bottom: 10px;
    border-bottom: solid 3px #e5e5e5;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        bottom: -3px;
        left: 0;
        display: block;
        clear: both;
        height: 3px;
        background-color: $green;
        width: 280px;
    }
}
.headLine3 {
    margin: 30px 0;
    background-color: #e8fcef;
    color: $green;
    font-size: 1.8rem;
    font-weight: bold;
    border-radius: 5px;
    padding: 5px 15px;
}
.headLine4 {
    font-size: 1.8rem;
    font-weight: bold;
    padding: 5px;
    border-bottom: dotted 2px $green;
    margin: 20px 0;
}
.headLine5 {
    font-size: 1.8rem;
    font-weight: bold;
    margin: 20px 0;
}
.f14 {
    font-size: 1.4rem;
}
.f18 {
    font-weight: bold;
    font-size: 1.8rem;
}
.f22 {
    font-size: 2.2rem;
}
@media screen and (max-width: $breakpoint-sp){
    .headLine2 {
        margin: 40px 0 20px;
        &:after {
            width: 75px;
        }
    }
}


@media screen and (max-width: $breakpoint-sp){
    .scrollTable {
        $this: &;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        & > table {
            width: auto;
            white-space: nowrap;
        }
    }
    .gMap {
        position: relative;
        padding-top: calc(100% * 500 / 880);
        margin-top: 20px;
        & > iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
/*
ぱんくず

Markup:
============================================================*/
.pankuzu {
    margin: 0 auto;
    padding: 20px 0 40px;
    background-color: #fff;
    width: 1200px;
    &_list {
        margin-top: 0;
        display: flex;
        li + li {
            &:before {
                @include fa();
                content: '\f105';
                color: #898989;
                margin: 0 1em;
            }
        }
    }
    &_item {
        display: block;
        font-size: 1.3rem;
        white-space: nowrap;
        color: #898989;
        a {
            color: #898989;
            font-weight: normal;
        }
        &:last-child {
            word-break: break-all;
            white-space: initial;
            margin-left: 2.3em;
            text-indent: -2.3em;
            // overflow: hidden;
            // white-space: nowrap;
            // text-overflow: ellipsis;
        }
        &-home {
            i:before {
                content: url(../images/content/icon_home.png);
                position: relative;
                top: 3px;
            }
        }
    }
}
@media screen and (max-width: $breakpoint-sp){
    .pankuzu {
        overflow-x: scroll;
        width: 100%;
        padding: 10px 0 10px;
        &_list {
            justify-content: flex-start;
            padding: 8px;
        }
        &_item {
            white-space: nowrap;
            &:last-child {
                padding-right: 8px;
                white-space: nowrap;
            }
        }
        &::-webkit-scrollbar {
            height: 5px;
        }
        &::-webkit-scrollbar-track {
            background-color: #fafbf6;
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgba( 238, 238, 238, 1);
        }
    }
}
/*
サイド

Markup:
============================================================*/
.sideBox {
    margin-top: 25px;
    &_title {
        font-size: 1.5rem;
        font-weight: bold;
        padding: 15px;
        color: $green;
        background-color: #e8fcef;
    }
    &_body {
        border-left: solid 1px #e5e5e5;
        border-right: solid 1px #e5e5e5;
        border-bottom: solid 1px #e5e5e5;
        padding: 0 15px;
    }
    &_box {
        border: solid 1px #e5e5e5;
        padding: 15px;
    }
}
.tree {
    &_list {
        margin-top: 0;
    }
    &_item {
        display: block;
        border-top: dotted 1px #b2b2b2;
        a {
            font-size: 1.4rem;
            display: block;
        }
        &:last-child {
            margin-bottom: 0;
        }
        &:first-child {
            border-top: none;
        }
    }
    &_head {
        position: relative;
        a {
            padding: 15px 25px 15px 10px;
        }
        span:not([class]) {
            display: block;
            font-weight: bold;
            font-size: 1.3rem;
            padding: 15px 25px 15px 10px;
            &:before {
                @include fa();
                content: '\f0da';
                color: $green;
                position: absolute;
                left: 0;
                top: 18px;
                margin-right: 5px;
            }
        }
    }
    &_toggle {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        width: 15px;
        height: 15px;
        cursor: pointer;
        z-index: 2;
        padding: 0 !important;
        &:before {
            content: '－';
            font-size: 1.2rem;
            text-align: center;
            display: block;
            width: 15px;
            line-height: 15px;
            height: 15px;
            color: $green;
            border: solid 1px $green;
            border-radius: 50%;
        }
        &-isOpen {
            &:before {
                content: '＋';
                line-height: 15px;
            }
        }
    }
    &_subList {
        display: block;
        margin-top: 0;
        padding-left: 10px;
        &-isOpen {
            display: none;
        }
    }
    &_subItem {
        display: block;
        a,span {
            font-size: 1.4rem;
            display: block;
            padding: 7px 10px;
        }
        .tree-active {
            position: relative;
            &:before {
                @include fa();
                position: absolute;
                content: '\f105';
                top: 12px;
                margin-right: 5px;
            }
        }
    }
    &-active {
        color: $green;
        &:before {
            @include fa();
            content: '\f105';
            color: $green;
            font-weight: bold;
            position: absolute;
            left: 0;
            top: 18px;
            margin-right: 5px;
        }
    }
}
@media screen and (max-width: $breakpoint-sp){
    .tree {
        &_list {
        }
        &_childItem {
            a {
            }
        }
    }
}
/*
ページャー

Markup:
<div class="pager">
    <ul class="pager_list">
        <li class="pager_item pager_item-prev"><a href="#">&lt;</a></li>
        <li class="pager_item"><a href="#">1</a></li>
        <li class="pager_item next_item-dot"><a href="#">&#8230;</a></li>
        <li class="pager_item"><a href="#">2</a></li>
        <li class="pager_item"><a href="#">3</a></li>
        <li class="pager_item pager_item-current"><span>4</span></li>
        <li class="pager_item"><a href="#">5</a></li>
        <li class="pager_item"><a href="#">6</a></li>
        <li class="pager_item next_item-dot"><a href="#">&#8230;</a></li>
        <li class="pager_item sp-hidden"><a href="#">15</a></li>
        <li class="pager_item pager_item-next"><a href="#">&gt;</a></li>
    </ul>
</div>
============================================================*/
.pager {
    margin-top: 40px;
    text-align: center;
    &_list {
        font-size: 0;
        margin: 20px 0 10px;
        a {
            font-size: 1.4rem;
        }
    }
    &_item {
        display: inline-block;
        min-width: 30px;
        line-height: 30px;
        margin: 0 3px;
        a {
            display: block;
        }
        &-prev {
            line-height: 30px;
            min-width: 30px;
            border-radius: 3px;
            border: solid 1px $green;
            a:before {
                @include fa();
                font-size: 2rem;
                content: '\f104';
                color: $green;
                border-radius: 3px;
                position: relative;
                top: 1px;
            }
        }
        &-next {
            line-height: 30px;
            min-width: 30px;
            border-radius: 3px;
            border: solid 1px $green;
            a:after {
                @include fa();
                font-size: 2rem;
                content: '\f105';
                color: $green;
                position: relative;
                top: 1px;
            }
        }
        &-current {
            span {
                text-align: center;
                color: #fff;
                display: block;
                font-size: 1.4rem;
                background-color: $green;
                border-radius: 3px;
            }
        }
        &-dot {
            span {
                display: block;
                color: #888888;
                font-size: 1.6rem;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-sp){
    .pager {
        margin-top: 30px;
        &_item {
            // margin: 0;
            &-prev {
                span {
                    display: none;
                }
                a:before {
                    margin-right: 0;
                }
            }
            &-next {
                span {
                    display: none;
                }
                a:after {
                    margin-left: 0;
                }
            }
        }
    }
}
/*
サイトマップエリア

Markup:
{$modifiers}

.btn-red - red

Styleguide 0.0.0
============================================================*/
.layoutArea {
    padding: 0 20px!important;
    max-width: initial !important;
}
.section:first-child {
    margin-top: 0;
}
.siteList {
    &_list {
        letter-spacing: -.44em;
        position: relative;
        &:before {
            position: absolute;
            display: block;
            top: 50%;
            left: 0;
            content: "";
            min-width: 100%;
            height: 5px;
            margin-top: 5px;
            background-color: #666;
        }
    }
    &_item {
        display: inline-block;
        letter-spacing: normal;
        position: relative;
        vertical-align: middle;
        margin: 20px 20px 0 0;
        padding: 6px;
        background-color: #fff;
        border: 1px solid #ccc;
        &:first-child {
            margin-left: 0;
        }
        a {
            position: relative;
            display: block;
            width: 140px;
            height: 80px;
            text-align: center;
            font-size: 12px;
            line-height: 1.33333;
            text-decoration: none;
            &:before {
                top: 5px;
                left: 5px;
                @include fa();
                content: "\f00b";
                z-index: 5;
            }
        }
        span {
            display: block;
            position: relative;
            top: 50%;
            text-align: center;
            transform: translateY(-40%);
        }
    }
}
.siteList_item > a:before, .siteList_item > a:after, .siteList_item > div:before, .siteList_item > div:after {
    position: absolute;
    display: inline-block;
    font-family: FontAwesome;
    font-size: 16px;
    line-height: 1;
    text-decoration: none;
}
.ok {
    a {
        background-color: $green;
        color: #fff;
    }
}
.add {
    a {
        background-color: #f49c00;
        color: #fff;
    }
}
.ok > a:after, .ok > div:after {
    @include fa();
    bottom: 5px;
    right: 5px;
    content: "\f00c";
    color: #fff;
}
.ng {
    a {
        background-color: #005982;
        color: #fff;
    }
}
.ng > a:after, .ng > div:after {
    @include fa();
    bottom: 5px;
    right: 5px;
    content: "\f071";
    color: #ff0;
}

@media screen and (max-width: $breakpoint-sp){
    .siteList_list {
        border-top: 5px solid grey;
        margin: 5% 0 0;
        &:before {
            display: none;
        }
    }
    .siteList_item {
        margin: 3% 3% 0 0;
        padding: 6px;
        width: 46%;
    }
    .siteList_item>a, .siteList_item>div {
        width: auto;
    }
    a, button {
        transition: none;
    }
}
/*
btns ボタン

Markup:
============================================================*/
.btns {
    &_btn {
        margin: 20px 10px 0;
        display: block;
        width: 360px;
        &-s {
            width: 220px;
        }
    }
    &-hr {
        display: flex;
        display: -webkit-flex;
        justify-content: center;
        -webkit-justify-content: center;
    }
    li {
        display: block;
    }
}
.btn {
    text-align: center;
    color: #fff;
    background: linear-gradient(to right,#3eac64,#50da7f);
    box-shadow: 0 2px #aff0d0;
    display: block;
    border-radius: 20px;
    padding: 8px 10px;
    &:before {
        @include fa();
        content: '\f105';
        margin-right: 5px;
    }
    &-green {
        background-color: $green;
    }
    &-return {
        background: #fff;
        border: solid 1px $green;
        box-shadow: none;
        color: $green;
        &:before {
            content: '\f104';
        }
    }

}
@media screen and (max-width: $breakpoint-sp){
    .btns {
        &-hr {
            display: block;
        }
        &_btn {
            margin: 20px 0 0;
            width: 100%;
        }
    }
    .btn {
        margin: 0 auto;
    }
}