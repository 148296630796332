@import '_config';
@import '_sprite';

/*
gHeader

Markup:
<header class="gHeader">
	<div class="gHeader_inner">
		<p class="gNavBtn pc-hidden"><a href="javascript: void(0);"><span></span></a></p>
		<div class="gHeader_left">
			<div class="gHeader_logo"><a href="#"><img src="http://satyr.io/240x70/dddddd" alt=""></a></div>
			<!-- <div class="gHeader_logo"><a href="#"><img src="./assets/images/global/header_logo.png" alt=""></a></div> -->
		</div>
		<div class="gHeader_right">
			<ul class="gHeader_links">
				<li class="gHeader_link gHeader_language"><a href="#">English</a></li>
				<li class="gHeader_link gHeader_tell"><a href="#">04-7147-7355</a></li>
				<li class="gHeader_link gHeader_fax"><a href="#">04-7147-7356</a></li>
				<li class="gHeader_link gHeader_contact"><a href="#">お問い合わせ</a></li>
			</ul>
			<nav class="gNav">
				<div class="gNav_inner">
					<p class="gNav_close pc-hidden"><a href="javascript: void(0);"><span><i>close</i></span></a></p>
					<ul class="gNav_list">
						<li class="gNav_item"><a href="">事業案内</a></li>
						<li class="gNav_item"><a href="">企業情報</a></li>
						<li class="gNav_item"><a href="">採用情報</a></li>
						<li class="gNav_item"><a href="">アセットアカデミー</a></li>
						<li class="gNav_item"><a href="">新着情報</a></li>
					</ul>
				</div>
			</nav><!-- gNav -->
		</div>
	</div><!-- gHeader_inner -->
</header><!-- gHeader -->
============================================================*/
.gHeader {
	padding: 15px 0;
	&_inner {
		width: 1200px;
		margin: 0 auto;
		display: flex;
		display: -webkit-flex;
		justify-content: space-between;
		-webkit-justify-content: space-between;
	}
	&_left {
	}
	&_logo {
		margin-top: 10px;
	}
	&_right {
	}
	&_links {
		margin-top: 0;
		display: flex;
		display: -webkit-flex;
		justify-content: flex-end;
		-webkit-justify-content: flex-end;
		align-items: center;
		-webkit-align-items: center;
		li + li {
			margin-left: 30px;
		}
	}
	&_link {
		display: block;
	}
	&_language {
		font-size: 1.4rem;
		i:before {
		    @include fa();
		    content: '\f105';
		    margin-right: 5px;
		}
	}
	&_tell {
		font-size: 2.1rem;
		font-weight: bold;
		i:before {
		    content: url(../images/global/logo_tell.png);
		    position: relative;
			top: 3px;
			margin-right: 5px;
		}
	}
	&_fax {
		font-size: 2.1rem;
		font-weight: bold;
		i:before {
		    content: url(../images/global/logo_fax.png);
		    position: relative;
			top: 3px;
			margin-right: 5px;
		}
	}
	&_contact {
		width: 220px;
		i:before {
		    content: url(../images/global/icon_mail.png);
		    position: relative;
			top: 1px;
			margin-right: 5px;
		}
		a {
			width: 220px;
			text-align: center;
		    color: #fff;
		    background: linear-gradient(to right,#3eac64,#50da7f);
		    box-shadow: 0 2px #b5f1d1;
		    display: block;
		    border-radius: 20px;
		    padding: 8px 10px;
		}
	}
}
@media screen and (max-width: $breakpoint-sp){
	.gHeader {
		padding: 20px 10px;
		&_inner {
			width: 100%;
			display: block;
		}
		&_logo {
			margin-top: 0;
		}
		&_links {
			display: block;
			> li + li {
				margin: 10px auto 0;
			}
		}
		&_link {
			text-align: center;
		}
		&_left {
			text-align: center;
			img {
				width: 120px;
			}
		}
	}
}




.gNav {
	&_inner {
	}
	&_close {
	}
	&_list {
		margin-top: 0;
		display: flex;
		display: -webkit-flex;
	}
	&_item {
		display: block;
		a {
			display: block;
			font-weight: bold;
			font-size: 1.7rem;
			padding: 20px 55px;
		}
		&:last-child {
			a {
				padding-right: 0;
			}
		}
		&-isActive {
			a {
				color: $green;
			}
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.drawer {
		position: fixed;
	    top: 0;
	    right: -100%;
	    width: 90%;
	    height: 100%;
	    z-index: 10000;
	    padding: 40px 0;
	    background-color: #fff;
	    overflow-y: auto;
        -webkit-overflow-scrolling: touch;
		-webkit-transition: right .4s;
		transition: right .4s;
		border-top: solid 1px #eee;
		border-bottom: none;
		margin: 0;
	}
	.gNav {
		// position: fixed;
	 //    top: 0;
	 //    right: -100%;
	 //    width: 90%;
	 //    height: 100%;
	 //    z-index: 10000;
	 //    padding: 0;
	 //    background-color: #fff;
	 //    overflow-y: auto;
  //       -webkit-overflow-scrolling: touch;
		// -webkit-transition: right .4s;
		// transition: right .4s;
		// border-top: solid 1px #eee;
		// border-bottom: none;
		margin-top: 20px;
		&_inner {
			width: 100%;
			padding: 0;
			box-shadow: none;
		}
		&_list {
			margin: 15px 0 0;
			display: block;
			li + li {
				margin-left: 0;
			}
		}
		&_item {
			display: block;
			width: 100%;
			padding: 0;
			margin-bottom: 0;
			border-top: 1px #b8b8b8 solid;
			background: none;
			height: auto;
			> a {
				display: block;
				line-height: 1.6;
				font-size: 1.6rem;
				padding: 15px;
				border-left: none;
				display: flex;
				display: -webkit-flex;
				align-items: center;
				-webkit-align-items: center;
			}
			&-toggle {
				position: relative;
			}
			&:last-child {
				border-bottom: 1px #b8b8b8 solid;
			}

		}
		&_close {
            position: absolute;
            top: 13px;
            right: 0;
            margin-top: 0;
            a
            {
                position: relative;
                display: block;
                width: 40px;
                height: 38px;
                margin: 0 auto;
                text-align: center;
                text-decoration: none;
            }
            span::before,
            span::after {
                position: absolute;
                top: 50%;
                left: 50%;
                display: inline-block;
                width: 22px;
                height: 2px;
                margin-top: -10px;
                margin-left: -11px;
                content: '';
                background-color: #333;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
            }
            span {
                &:before {
                    transform: translateY(0) rotate(-45deg);
                }
                &:after {
                    transform: translateY(0) rotate(45deg);
                }
                i {
                    font-size: 12.8px;
                    font-style: normal;
                    display: block;
                    padding-top: 22px;
                    text-align: center;
                    text-decoration: none;
                    color: #777;
                }
            }
        }
	}
	// ハンバーガーボタン
	.gNavBtn {
		position: absolute;
		top: 10px;
		right: 5px;
		display: inline-block;
		margin: 0;
		vertical-align: middle;
		z-index: 100;
	}
	.gNavBtn > a {
		position: relative;
		display: block;
		width: 40px;
		height: 40px;
		background: $green;
	}
	.gNavBtn > a:hover {
		opacity: 1;
	}
	.gNavBtn > a span,
	.gNavBtn > a:before,
	.gNavBtn > a:after {
		display: block;
		width: 18px;
		height: 2px;
		background: #fff;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: 0px 0 0 -9px;
		-webkit-transition: all .4s;
		transition: all .4s;
		z-index: 20000000;
	}
	.gNavBtn > a:before, .gNavBtn > a:after {
		content: '';
	}
	// 三本線の上と下の位置
	.gNavBtn > a:before {
		-webkit-transform: translateY(-8px);
		-ms-transform: translateY(-8px);
		transform: translateY(-8px);
	}
	.gNavBtn > a:after {
		-webkit-transform: translateY(8px);
		-ms-transform: translateY(8px);
		transform: translateY(8px);
	}
	.gNavOverlay,
	.gHeader_contOverlay {
		background: #ccc;
		opacity: 0;
		z-index: 500;
		-webkit-transition: all .4s;
		transition: all .4s;
	}
	.gNavOverlay-isOpen,
	.gHeader_contOverlay-isOpen {
		display: block;
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: .8;
	}
	.gNavBtn-isOpen a span {
		opacity: 0;
	}
	// ×(バツ)の挙動
	.gNavBtn-isOpen a:before {
		-webkit-transform: translateY(0) rotate(-45deg);
		-ms-transform: translateY(0) rotate(-45deg);
		transform: translateY(0) rotate(-45deg);
	}
	.gNavBtn-isOpen a:after {
		-webkit-transform: translateY(0) rotate(45deg);
		-ms-transform: translateY(0) rotate(45deg);
		transform: translateY(0) rotate(45deg);
	}
	// ボタンクリック後のメニュースライド
	.drawer-isOpen {
		right: 0;
	}
	.gNav:-webkit-scrollbar {
		cursor: pointer;
		width: 10px;
	}
	.gNav:-webkit-scrollbar-track {
		background: #E5E5E5;
		cursor: pointer;
	}
	.gNav:-webkit-scrollbar-thumb {
		background: #000;
		cursor: pointer;
	}
}

/*
gFooter

Markup:
<footer class="gFooter">
	<div class="gFooter_inner">
		<p class="gFooter_logo"><img src="./assets/images/global/footer_logo.png" alt="株式会社アセットオール"></p>
		<div class="gFooter_sitemap">
			<ul class="gFooter_blockList">
				<li class="gFooter_blockItem">
					<ul class="gFooter_set">
						<li class="gFooter_setTitle"><a href="#">事業内容</a></li>
						<li class="gFooter_setLink"><a href="#">English</a></li>
					</ul>
				</li>
				<li class="gFooter_blockItem">
					<ul class="gFooter_set">
						<li class="gFooter_setTitle"><a href="#">企業情報</a></li>
						<ul class="gFooter_hrLinks">
							<li class="gFooter_hrLink"><a href="#">会社概要</a></li>
							<li class="gFooter_hrLink"><a href="#">関連会社</a></li>
							<li class="gFooter_hrLink"><a href="#">沿革</a></li>
							<li class="gFooter_hrLink"><a href="#">主要取引先</a></li>
							<li class="gFooter_hrLink"><a href="#">営業拠点</a></li>
							<li class="gFooter_hrLink"><a href="#">SDGs</a></li>
						</ul>
					</ul>
				</li>
				<li class="gFooter_blockItem">
					<ul class="gFooter_set">
						<li class="gFooter_setTitle"><a href="#">採用情報</a></li>
						<li class="gFooter_setTitle"><a href="#">アセットアカデミー</a></li>
						<li class="gFooter_setTitle"><a href="#">新着情報</a></li>
						<li class="gFooter_setTitle"><a href="#">お問い合わせ</a></li>
					</ul>
				</li>
				<li class="gFooter_blockItem">
					<ul class="gFooter_set">
						<li class="gFooter_setLink"><a href="#">個人情報保護方針</a></li>
						<li class="gFooter_setLink"><a href="#">情報セキュリティ</a></li>
						<li class="gFooter_setImgs"><img src="" alt=""></li>
					</ul>
				</li>
			</ul><!-- gFooter_blockList -->
		</div><!-- gFooter_sitemap -->
		<div class="gFooter_section">
			<p class="gFooter_copyright">&copy;aaaaaaaaaaaaa. All Rights Reserved.</p>
		</div>
	</div><!-- gFooter_section -->
</footer><!-- gFooter -->
<p class="pageTop"><a href="#"><img src="./assets/images/global/pageTop.png" alt="ページトップへ"></a></p>
============================================================*/
.gFooter {
	padding: 35px 0 15px;
	&_inner {
		width: 1200px;
		margin: 0 auto;
	}
	&_logo {
		text-align: center;
		margin-top: 0;
	}
	&_sitemap {
	}
	&_blockList {
		display: flex;
		display: -webkit-flex;
		justify-content: space-between;
		-webkit-justify-content: space-between;
	}
	&_blockItem {
		padding: 5px 25px;
		display: block;
		border-left: solid 1px #b2b2b2;
		&:first-child {
			border-left: none;
		}
	}
	&_set {
		margin-top: 0;
		> li + li {
			margin-top: 10px;
		}
	}
	&_setTitle {
		display: block;
		font-weight: bold;
		font-size: 1.4rem;
	}
	&_setLink {
		font-size: 1.3rem;
		font-weight: 500;
		display: block;
	}
	&_hrLinks {
		margin-top: 0;
		display: flex;
		display: -webkit-flex;
		flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
		li + li {
			margin-top: 5px;
		}
	}
	&_hrLink {
		width: 50%;
		display: block;
		font-size: 1.3rem;
		font-weight: 500;
		padding-left: 1em;
	}
	&_setImgs {
		display: block;
	}
	&_section {
		margin-top: 35px;
		border-top: solid 1px #e5e5e5;
	}
	&_copyright {
		font-size: 1.1rem;
		text-align: center;
	}
}
@media screen and (max-width: $breakpoint-sp){
	.gFooter {
		padding: 20px 0 10px;
		&_inner {
			width: 100%;
		}
		&_sitemap {
			display: none;
		}
		&_logo {
			img {
				width: 150px;
			}
		}
		&_section {
			margin-top: 15px;
		}
		&_copyright {
			margin-top: 10px;
		}
	}
}
.pageTop {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 2;
	a {
		display: block;
		&:before {
		    @include fa();
		    content: '\f106';
		    font-size: 3rem;
		    color: #fff;
		    display: block;
		    width: 50px;
		    line-height: 50px;
		    text-align: center;
		    background-color: rgba(136, 136, 136, .5);
		}
	}
}
@media screen and (max-width: $breakpoint-sp){
	.pageTop {
		bottom: 10px;
		right: 10px;
	}
}